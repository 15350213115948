<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="标签名称"
          color="primary"
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmBodycheckTagsListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmBodycheckTagsListLoading" @click="onExportData">导出</q-btn> -->
        <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmBodycheckTagsListLoading"
          @click="onAdd"
          >新增</q-btn
        >
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 140"
        :loading="PdmBodycheckTagsListLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          >
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmBodycheckTagsshowDlg" persistent>
    <BodycheckTagsEdit @onLoadData="onLoadData" />
  </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import BodycheckTagsEdit from "./bodycheckTagsEdit.vue";
export default {
  components: {
    BodycheckTagsEdit,
  },
  computed: {
    ...mapState("PdmBodycheckTags", [
      "PdmBodycheckTagsList",
      "PdmBodycheckTagsListLoading",
      "PdmBodycheckTagsEntity",
      "PdmBodycheckTagsShowDlg",
    ]),

    pdmBodycheckTagsshowDlg: {
      get() {
        return this.PdmBodycheckTagsShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmBodycheckTags/setPdmBodycheckTagsShowDlg", val);
      },
    },
  },
  data() {
    return {
      FilterString: "",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "HospName",
            title: "机构名称",
            sortable: true,
            align: "left",
            width: 260,
          },
          {
            field: "Tags_Name",
            title: "标签名称",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            title: "操作",
            width: 200,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmBodycheckTagsList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmBodycheckTags", [
      "actGetPdmBodycheckTagsListByPage",
      "actCreatePdmBodycheckTags",
      "actEditPdmBodycheckTagsData",
      "actEditPdmBodycheckTags",
      "actDeletePdmBodycheckTags",
    ]),
    onLoadData() {
      this.actGetPdmBodycheckTagsListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckTags/setPdmBodycheckTagsListLoading",
            false
          );
          //this.PdmBodycheckTagsListLoading = false;
        });
    },
    onAdd() {
      this.actCreatePdmBodycheckTags({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmBodycheckTags(obj);
    },
    onDelete(e) {
      this.actDeletePdmBodycheckTags({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
